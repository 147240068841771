<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}} - ตั้งค่า</h3>
      </div>
    </div>

    <hr class="my-3">

    <div class="">
      <div class="form-row">
        <sgv-input-select
          placeholder="เลือกรายการ..."
          class="col-12"
          label="ประเภท"
          :options="options"
          select="id"
          v-model="selected">
          <template slot-scope="option">
            {{option.id}} ({{option.description}})
          </template>
        </sgv-input-select>
      </div>

      <DocConfigListReport
        v-if="['reportGroup'].includes(selected)"
        :docType="docType"
        :templateType="templateType"
        :reportType="selected">
      </DocConfigListReport>

      <router-view v-else></router-view>
    </div>
  </div>
</template>

<script>
import DocConfigListReport from '@/views/doc_core/components/DocConfigListReport.vue'
import { LIST_DOC_CONFIG_MENU } from './graph'


export default {
  metaInfo () {
    return {
      title: `${this.title}-ตั้งค่า`
    }
  },
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      menus: {},
      selected: ''
    }
  },
  apollo: {
    menus: {
      query () {
        return LIST_DOC_CONFIG_MENU(this.templateType)
      },
      variables () {
        return {docType: this.docType}
      },
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    options () {
      const arr = [
        {description: 'พนักงาน', id: 'employee'},
        {description: 'ตำแหน่งงาน', id: 'jobDescription'},
        {description: 'หักค่าใช้จ่าย', id: 'discount'},
        {description: 'ประกันสังคม', id: 'socialSecurity'},
        {description: 'ฐานภาษี', id: 'tax'},
        {description: 'ค่าแรงค้างจ่าย', id: 'netPrice'},
      ]

      Object.keys(this.menus).forEach(v => {
        arr.push({
          id: v,
          attributes: this.menus[v].attributes,
          description: this.menus[v].description,
          optionals: this.menus[v].optionals,
          contactTypes: this.menus[v].contactTypes || []
        })
      })

      return arr
    },
  },
  methods: {
    getType () {
      if (this.$route.name === `Doc${this.$form.capitalize(this.docType)}DocConfigList`) {
        return null
      }

      const arr = this.$route.path.split('/')
      const selected = arr[arr.length-2]
      return selected
    },
    toList () {
      return {
        name: `Doc${this.$form.capitalize(this.docType)}List`
      }
    },
  },
  watch: {
    selected (value) {
      if (this.getType() === value) return

      if (value) {
        this.$router.push({
          name: `Doc${this.$form.capitalize(this.docType)}DocConfigList${this.$form.capitalize(value)}`,
        })
      } else {
        this.$router.push({
          name: `Doc${this.$form.capitalize(this.docType)}DocConfigList`,
        })
      }
    }
  },
  created () {
    this.selected = this.getType() || null
  },
  components: {
    DocConfigListReport
  }
}
</script>

<style lang="css">
</style>
